import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import signup_teaser from '../../assets/images/signup_teaser_animation_2.gif';
import signup_teaser_smorg_studio from '../../assets/images/signup_teaser_smorg_studio.png';
import AuthHeader from '../user/auth_header';
import image_step_name_password from '../../assets/images/onboarding/step_name_password.png';

const CreateAccountStepNamePassword = ({
  email,
  givenName,
  familyName,
  cleartextPassword,
  setGivenName,
  setFamilyName,
  setCleartextPassword,
  onNext,
  onBack,
  errorMessage,
  isLoading,
  isForCreators,
  disableTeaser,
  spaceTitle,
}) => {
  const teaserStyle = isForCreators ? { marginTop: 20 } : {};

  const wizardTotalSteps = isForCreators ? 4 : 3;

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const termsUrl = isForCreators
    ? 'https://www.mealiq.io/smorg-service-terms-and-conditions'
    : 'https://www.smorg.io/terms';

  return (
    <main className="authForm">
      {!disableTeaser && (
        <>
          <AuthHeader isForCreators={isForCreators} />
          <br />
          <img
            src={isForCreators ? signup_teaser_smorg_studio : signup_teaser}
            className="authTeaser"
            alt="Are you ready?"
            style={teaserStyle}
          />
        </>
      )}
      <Dialog
        open
        maxWidth="md"
        fullWidth
        BackdropProps={{ invisible: true }}
        className={classNames([
          'authDialog',
          'signupDialog',
          'onboardingStep',
          { withTeaser: !disableTeaser },
        ])}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DialogContent>
              <div className="wizardStep">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onBack();
                  }}>
                  <FontAwesomeIcon icon="chevron-left" />
                </a>
                &nbsp; Step 2 of {wizardTotalSteps}
              </div>
              {spaceTitle && <h1>{spaceTitle}</h1>}
              <h2>Now let&rsquo;s set up your profile</h2>
              <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                  <DialogContentText>What&rsquo;s your name?</DialogContentText>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={givenName}
                    onChange={(ev) => {
                      setGivenName(ev.target.value);
                    }}
                    autoFocus
                    margin="dense"
                    label="First name *"
                    type="text"
                    autoComplete="given-name"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={familyName}
                    onChange={(ev) => {
                      setFamilyName(ev.target.value);
                    }}
                    margin="dense"
                    label="Surname *"
                    type="text"
                    autoComplete="family-name"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DialogContentText>Set a password</DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="email"
                    value={email}
                    autoComplete="username"
                    style={{ display: 'none' }}
                  />
                  <TextField
                    value={cleartextPassword}
                    onChange={(ev) => {
                      setCleartextPassword(ev.target.value);
                    }}
                    margin="dense"
                    label="Password *"
                    type="password"
                    autoComplete="new-password"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreedToTerms}
                        onChange={(ev) => {
                          setAgreedToTerms(ev.target.checked);
                        }}
                      />
                    }
                    label={
                      <>
                        I agree to the{' '}
                        <a href={termsUrl} target="_blank" rel="noreferrer">
                          terms of service
                        </a>{' '}
                        {/* and{' '}
                        <a
                          href="https://www.smorg.io/privacy"
                          target="_blank"
                          rel="noreferrer">
                          privacy policy
                        </a> */}
                      </>
                    }
                  />
                </Grid>
              </Grid>
              {errorMessage && (
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ margin: 'auto 10px', padding: '6px' }}>
                  <Button
                    variant="contained"
                    onClick={onNext}
                    disabled={isLoading || !agreedToTerms}>
                    Create my account
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
          <Grid item sm={6} className="decorationImage">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={image_step_name_password} style={{ height: 468 }} />
          </Grid>
        </Grid>
      </Dialog>
    </main>
  );
};

CreateAccountStepNamePassword.propTypes = {
  email: PropTypes.string.isRequired,
  givenName: PropTypes.string,
  familyName: PropTypes.string,
  cleartextPassword: PropTypes.string,
  setGivenName: PropTypes.func.isRequired,
  setFamilyName: PropTypes.func.isRequired,
  setCleartextPassword: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isForCreators: PropTypes.bool.isRequired,
  disableTeaser: PropTypes.bool,
  spaceTitle: PropTypes.string,
};

CreateAccountStepNamePassword.defaultProps = {
  givenName: null,
  familyName: null,
  cleartextPassword: null,
  errorMessage: null,
  disableTeaser: false,
  spaceTitle: null,
};

export default CreateAccountStepNamePassword;
