import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  endUserSwitchToSpaceAction,
  loginAction,
} from '../../action_creators/app_load_action_creators';
import AuthHeader from './auth_header';
import { linkWithQueryParams } from '../../services/urls';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';
import {
  END_USER_SIGNUP_ROUTE,
  SIGNUP_SMORG_COMPANION_ROUTE,
} from '../../services/routes';
import DarkBackgroundTextField from './darkbackground_textfield';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [cleartextPassword, setCleartextPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const intoSpaceID = searchParams.get('intoSpace');
  const membershipTierID = searchParams.get('chosenMembershipTier');

  const isRunningInsideSmorgCompanion = isEmbeddedInSmorgCompanion();

  useEffect(() => {
    if (intoSpaceID) {
      dispatch(endUserSwitchToSpaceAction(intoSpaceID));
    }
  }, [dispatch, intoSpaceID]);

  const spaceBackgroundColor = useSelector(
    (state) =>
      state.spaces.find((s) => s.id === intoSpaceID)?.whiteLabelElements
        ?.backgroundColor || '#427A46',
  );

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      await dispatch(loginAction(email, cleartextPassword, intoSpaceID));
      if (returnTo) {
        window.location.href = returnTo;
      } else {
        navigate(
          linkWithQueryParams('/', { chosenMembershipTier: membershipTierID }),
        );
      }
    } catch (err) {
      console.log({ err });
      setErrorMessage('Login failed, please check your email and/or password');
    } finally {
      setIsLoading(false);
    }
  };

  const resetErrorMessage = () => {
    setErrorMessage('');
  };
  const getLink = () => {
    if (isRunningInsideSmorgCompanion) {
      return (
        <DialogContentText sx={{ backgroundColor: spaceBackgroundColor }}>
          <div>
            Not yet subscribed?{' '}
            <Link
              to={linkWithQueryParams(SIGNUP_SMORG_COMPANION_ROUTE, {
                returnTo,
                intoSpace: intoSpaceID,
                chosenMembershipTier: membershipTierID,
              })}>
              Subscribe here
            </Link>
          </div>
        </DialogContentText>
      );
    }
    return (
      <DialogContentText sx={{ backgroundColor: spaceBackgroundColor }}>
        <div>
          Not yet subscribed?{' '}
          <Link
            to={linkWithQueryParams(END_USER_SIGNUP_ROUTE, {
              returnTo,
              intoSpace: intoSpaceID,
              chosenMembershipTier: membershipTierID,
            })}>
            Create an account
          </Link>
        </div>
      </DialogContentText>
    );
  };

  return (
    <main className="authForm">
      <AuthHeader />
      <Dialog
        open
        maxWidth="sm"
        className="authDialog darkBackgroundAuthForm"
        fullWidth
        BackdropProps={{ invisible: true }}>
        <DialogTitle
          sx={{ backgroundColor: spaceBackgroundColor, paddingBottom: '30px' }}>
          Login
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: spaceBackgroundColor }}>
          <DialogContentText>Email address</DialogContentText>
          <DarkBackgroundTextField
            value={email}
            onChange={(ev) => {
              setEmail(ev.target.value);
              resetErrorMessage();
            }}
            autoFocus
            margin="dense"
            type="email"
            fullWidth
            size="small"
            variant="outlined"
            style={{ margin: '10px 0' }}
          />
          <DialogContentText>Password</DialogContentText>
          <DarkBackgroundTextField
            value={cleartextPassword}
            onChange={(ev) => {
              setCleartextPassword(ev.target.value);
              resetErrorMessage();
            }}
            margin="dense"
            type="password"
            fullWidth
            size="small"
            variant="outlined"
            style={{ margin: '10px 0' }}
          />
          {errorMessage && (
            <div style={{ paddingTop: '5px', color: 'red' }}>
              {errorMessage}
            </div>
          )}
          <DialogContentText>
            Forgot your password?{' '}
            <Link
              to={linkWithQueryParams('/forgotPassword', {
                returnTo,
                intoSpace: intoSpaceID,
              })}>
              Click here to reset it
            </Link>
          </DialogContentText>
          <DialogContentText>
            <br />
            By continuing, you agree to the Smorg{' '}
            <a href="https://www.smorg.io/terms" target="_new">
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a href="https://www.smorg.io/privacy" target="_new">
              Privacy Policy
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'flex-start',
            paddingBottom: '50px',
            backgroundColor: spaceBackgroundColor,
          }}>
          <Box sx={{ padding: '0 20px' }}>
            {getLink()}
            <Box display="flex" style={{ marginTop: '20px' }}>
              <Button
                variant="contained"
                onClick={handleLogin}
                disabled={isLoading}>
                Login
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default LoginForm;
